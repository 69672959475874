<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">

            <a-form-model-item>
              <a-input placeholder="关键词" v-model="form.keyWord" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="设计编号" v-model="form.code" style="width: 150px" list="codeList">
              </a-input>
              <datalist id="codeList">
                <option v-for="item in codeList" :key="item">
                  {{ item }}
                </option>
              </datalist>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="合同名称" v-model="form.name" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="生产负责人" v-model="form.productionMasterName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input-number placeholder="年份" v-model="form.belongYear" style="width: 150px" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table bordered :data-source="list" :loading="loading" @change="onChange" :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }" rowKey="id">
        <a-table-column title="设计编号" width="120px" fixed="left" data-index="code" />

        <a-table-column title="合同名称">
          <template slot-scope="text">
            <span>
              {{ text.name }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="合同金额(万)" align="right">
          <template slot-scope="text">
            <Money :money="text.contractAmountSum" />
          </template>
        </a-table-column>

        <a-table-column title="合同数量" width="80px" align="right">
          <template slot-scope="text">
            <a-popover placement="bottomRight">
              <template slot="content">
                <a-table size="small" bordered :data-source="Array.isArray(text.contractList) ? text.contractList : []
                  " :pagination="false" rowKey="id">
                  <a-table-column title="设计编号" data-index="code" />
                  <a-table-column title="合同名" data-index="name" />
                  <a-table-column title="合同金额(万)" align="right">
                    <template slot-scope="text">
                      <Money :money="text.contractAmount" />
                    </template>
                  </a-table-column>
                  <a-table-column title="生产负责人" data-index="productionMasterName" align="center" width="100px" />
                </a-table>
              </template>
              <span style="color: #1890ff">
                {{ text.contractCount }}
                <a-icon type="down-circle" />
              </span>
            </a-popover>
          </template>
        </a-table-column>

        <a-table-column title="分包数量" width="80px" align="right">
          <template slot-scope="text">
            <a-popover placement="bottomRight">
              <template slot="content">
                <a-table size="small" bordered :data-source="Array.isArray(text.subList) ? text.subList : []"
                  :pagination="false" rowKey="id">
                  <a-table-column title="分包名" data-index="name" />
                  <a-table-column title="分包属性">
                    <template slot-scope="text">
                      <span v-if="text.isneeded === 1">设计类-必要分包</span>
                      <span v-if="text.isneeded === 0">设计类-非必要分包</span>
                      <span v-if="text.isneeded === 2">总承包类分包</span>
                    </template>
                  </a-table-column>
                  <a-table-column title="分包类别">
                    <template slot-scope="text">
                      <DataDictFinder dictType="subType" :dictValue="text.type" />
                      <DataDictFinder dictType="notNeedSubType" :dictValue="text.type" />
                      <DataDictFinder dictType="generalSubType" :dictValue="text.type" />
                    </template>
                  </a-table-column>

                  <a-table-column title="分包方" data-index="companyName" />
                  <a-table-column title="分包状态" align="center">
                    <template slot-scope="text">
                      <DataDictFinder dictType="subpackageStatus" :dictValue="text.status" iconType="text" />
                    </template>
                  </a-table-column>
                  <a-table-column title="预算金额(万)" align="right">
                    <template slot-scope="text">
                      <Money :money="text.planAmount" />
                    </template>
                  </a-table-column>
                </a-table>
              </template>
              <span style="color: #1890ff">
                {{ text.subCount }}
                <a-icon type="down-circle" />
              </span>
            </a-popover>
          </template>
        </a-table-column>
        <a-table-column title="累计确认进度" width="100px" align="right">
          <template slot-scope="text"> {{ text.progress }}% </template>
        </a-table-column>
        <a-table-column title="即时生产进度" width="100px" align="right">
          <template slot-scope="text"> {{ text.realTimeProgress }}% </template>
        </a-table-column>

        <a-table-column title="开票金额(万元)" width="100px" align="right">
          <template slot-scope="text">
            <a-popover placement="bottomRight">
              <template slot="content">
                <a-table size="small" bordered :data-source="Array.isArray(text.invoiceList) ? text.invoiceList : []
                  " :pagination="false" rowKey="id">
                  <a-table-column title="发票号码" data-index="invoiceCode" />
                  <a-table-column title="业务内容" data-index="business" />
                  <a-table-column title="开票日期" data-index="invoiceDate" align="center" />
                  <a-table-column title="发票金额(元)" align="right">
                    <template slot-scope="text">
                      <span v-if="parseFloat(text.totalPriceLowercase) < 0" style="color: #f00">{{
                        parseFloat(text.totalPriceLowercase).toLocaleString()
                      }}</span>
                      <span v-else style="color: #1890ff">{{
                        parseFloat(text.totalPriceLowercase).toLocaleString()
                      }}</span>
                    </template>
                  </a-table-column>
                  <a-table-column title="当日余额(元)" align="right">
                    <template slot-scope="text">
                      <span v-if="typeof text.sumRemainingThisday === 'number'">
                        <span v-if="text.sumRemainingThisday < 0" style="color: #f00">{{
                          text.sumRemainingThisday.toLocaleString() }}</span>
                        <span v-else style="color: #1890ff">{{
                          text.sumRemainingThisday.toLocaleString()
                        }}</span>
                      </span>
                    </template>
                  </a-table-column>

                  <a-table-column title="回款计划" align="center">
                    <template slot-scope="text">
                      <DataDictFinder dictType="account_collect_status" :dictValue="text.receivableStatus"
                        iconType="text" />
                    </template>
                  </a-table-column>
                </a-table>
              </template>

              <span :style="{ color: text.invoiceSum >= 0 ? '#1890ff' : '#f00' }">
                <Money :money="text.invoiceSum" />
                <a-icon type="down-circle" style="margin-left: 4px" />
              </span>
            </a-popover>
          </template>
        </a-table-column>

        <a-table-column title="回款金额(万元)" width="100px" align="right">
          <template slot-scope="text">
            <a-popover placement="bottomRight">
              <template slot="content">
                <a-table size="small" bordered :data-source="Array.isArray(text.paymentList) ? text.paymentList : []
                  " :pagination="false" rowKey="id">
                  <a-table-column title="回款金额(元)" align="center">
                    <template slot-scope="text">
                      <span v-if="parseFloat(text.account) < 0" style="color: #f00">{{
                        parseFloat(text.account).toLocaleString() }}</span>
                      <span v-else style="color: #1890ff">{{
                        parseFloat(text.account).toLocaleString()
                      }}</span>
                    </template>
                  </a-table-column>

                  <a-table-column title="回款日期" align="center" data-index="createAt" />
                  <a-table-column title="回款发票" align="center" data-index="invoiceCode" />
                </a-table>
              </template>
              <span :style="{ color: text.paymentSum >= 0 ? '#1890ff' : '#f00' }">
                <Money :money="text.paymentSum" />
                <a-icon type="down-circle" style="margin-left: 4px" />
              </span>
            </a-popover>
          </template>
        </a-table-column>


        <a-table-column title="项目状态" width="80px" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="project_project_status" :dictValue="text.projectStatus" />
          </template>
        </a-table-column>


        <a-table-column align="center" width="80px" fixed="right" title="操作">
          <template slot-scope="text">
            <a v-if="$getPermission('/produce/overview/detail')" @click.prevent="
              $router.push('/produce/overview/detail?id=' + text.id)
              " href="#">查看</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList } from "@/api/project/overview";
export default {
  name: "overview",

  mixins: [watermark],

  data() {
    return {
      form: {},
      codeList: [],

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    $route() {
      console.log(this.$route.query);
      const { keyWord } = this.$route.query;
      if (keyWord) {
        this.form = {
          keyWord,
        };
        console.log('watch: this.form', this.form)
        this.getList();
      }
    },
  },

  activated() {
    const { query } = this.$route;
    const { keyWord } = query || {};
    console.log('keyWord', keyWord)
    if (keyWord) {
      this.form = {
        keyWord,
      };
    }
    const str = window.localStorage.getItem("search-design-code");
    if (str) {
      this.codeList = JSON.parse(str);
    }

    console.log('activated this.form', this.form)
    this.getList();
  },

  methods: {
    getList() {
      this.saveCode();
      const { current, pageSize } = this;
      this.loading = true;

      console.log('getlist this.form', this.form)


      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);

            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    saveCode() {
      if (this.form.code) {
        if (this.codeList.indexOf(this.form.code) === -1) {
          this.codeList.unshift(this.form.code);
          if (this.codeList.length > 10) {
            this.codeList.pop();
          }
          window.localStorage.setItem(
            "search-design-code",
            JSON.stringify(this.codeList)
          );
        }
      }
    },
  },
};
</script>
